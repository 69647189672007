import "../App.css";
import Web from "../Images/web.svg";
import mobile from "../Images/mobile.svg";
import testing from "../Images/testing.svg";
import it from "../Images/it.svg";
import sap from "../Images/sap.svg"
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const ServiceOffered = () => {
    const { t } = useTranslation();
    return(
        <div className="home_container service_container">
            <div className="service_wrapper">
                <h1>{t('servicesOffered.heading')}</h1>
                <div className="service_wrapper_div">
                    <div className="first_service">
                         <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="black" class="bi bi-kanban" viewBox="0 0 16 16">
                            <path d="M13.5 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-11a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zm-11-1a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
                            <path d="M6.5 3a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1zm-4 0a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1zm8 0a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1z"/>
                        </svg>
                        <h3>{t('servicesOffered.services.s1.title')}</h3>
                        <p>{t('servicesOffered.services.s1.description')}</p>
                    </div>
                    <div className="first_service">
                        <img src={sap} alt="SAP Development" />
                        <h3>{t('servicesOffered.services.s2.title')}</h3>
                        <p>{t('servicesOffered.services.s2.description')}</p>
                        <Link to='/sapcontent'>{t('servicesOffered.services.s2.button')}</Link>
                    </div>
                    
                    <div className="first_service">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-cloud-check" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
                        <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
                        </svg>
                        <h3>{t('servicesOffered.services.s3.title')}</h3>
                        <p>{t('servicesOffered.services.s3.description')}</p>
                    </div>
                    <div className="first_service">
                        <img src={Web} alt="Web Development" />

                        <h3>{t('servicesOffered.services.s4.title')}</h3>
                        <p>{t('servicesOffered.services.s4.description')}</p>
                    </div>
                    <div className="first_service">
                        <img src={mobile} alt="Mobile Development" />

                        <h3>{t('servicesOffered.services.s5.title')}</h3>
                        <p>{t('servicesOffered.services.s5.description')}</p>
                    </div>
                     <div className="first_service">
                        <img src={testing} alt="Testing Service"/>

                        <h3>{t('servicesOffered.services.s6.title')}</h3>
                        <p>{t('servicesOffered.services.s6.description')}</p>
                    </div>
                    <div className="first_service">
                        <img src={it} alt="IT Consulting & Advisory" />
                        <h3>{t('servicesOffered.services.s7.title')} </h3>
                        <p>{t('servicesOffered.services.s7.description')}</p>
                    </div>
                </div>
            </div>
           
        </div>
    )
}

export default ServiceOffered
import MainContact from "../Components/ContactUsComponent/mainContact"
import Footer from "../Components/Footer"
import Navbar from "../Components/Navbar"

const ContactUs = ()=>{
    return(
        <>
            <section>
                <Navbar />
            </section>
            <section>
                <MainContact/>
            </section>
            <section>
                <Footer />
            </section>
        </>
    )
}
export default ContactUs
import { useTranslation } from "react-i18next";

const SecondSection = () => {
    const { t } = useTranslation();
    return(
        <div className="section_container">
            <div className="section_wrapper">
                <div className="section_img">
                    <img src="/img/hero_section2.png" alt="hii"/>
                </div>
                <div className="section_content">
                    <div className="section_content_wrapper">
                        <h1>{t('secondSection.heading')}</h1>
                        <p>{t('secondSection.description')}</p>
                        <button><a href="/about-us">{t('secondSection.buttonLabel')}</a></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecondSection;